@import "~@angular/material/prebuilt-themes/indigo-pink.css";

:root {
  /* chatbox colors*/
  --chatBackground: rgb(197, 208, 234);
  --chatBackground: -moz-linear-gradient(left, rgba(197, 208, 234, 1) 0%, rgba(217, 244, 250, 1) 100%);
  --chatBackground: -webkit-linear-gradient(left, rgba(197, 208, 234, 1) 0%, rgba(217, 244, 250, 1) 100%);
  --chatBackground: linear-gradient(to right, rgba(197, 208, 234, 1) 0%, rgba(217, 244, 250, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c5d0ea', endColorstr='#d9f4fa', GradientType=1);
  --skyBlue: rgb(99, 164, 255);
  --lightGrey: #717171;
  --sideToolBarWidth: 100px;
  --sideWindowBarWidth: 410px;
  --sideWindowBarWidth_negative: -410px;
  --sideWindowBarExtended: 85vw;
  --smallShadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.2);
  --infoWindowSHadow: 4px 0px 8px 0px rgba(0, 0, 0, 0.14);
  --markersSize: 70px;
  --toolBarClosed: 20px;
  --fullWidth: 100%;
  --success: #83db83;
  --danger: #db4343;
  --warning: #d6ca59;
  --mango: #ffbe32;
  --mustard: #b2914d;
  --lightGray: #ebebeb;
  --backDropColor: rgba(0, 0, 0, 0.6);
  --white: #ffffff;
  --lightBlue: #35b8d3;
  --titleGray: #595959;
  --darkGray: #8b8b8b;
  --borderGray: #b9b9b9;
  --chipBgColor: #c4c4c4;
  --skyBlueHover: rgba(99, 164, 255, 0.2);
  --fleetr-blue-hover: #5590e2;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --fleetr: #63A4FF;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #c6c6c6;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--skyBlue);
  border-radius: 20px;
}

@-moz-document url-prefix() {
  *{
    scrollbar-width: thin;
    scrollbar-color: var(--skyBlue) #89B1E9;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Source Sans Pro', sans-serif;
  height: 100dvh;
  background-color: var(--gray-100);
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button,
textarea {
  font-family: 'Source Sans Pro', sans-serif !important;
}

button {
  cursor: pointer;
}

h4 {
  font-weight: 700;
}

.spacer {
  flex: 1 1 auto;
}

.full-width {
  width: 100% !important
}

.leaflet-control-zoom {
  border: none !important;
}

.leaflet-control-zoom a {
  border-radius: 100px !important;
  margin: 10px 5px;
  color: #b8b8b8;
  line-height: 27px !important;
  border: 1px solid white;
}

.leaflet-control-zoom a:hover {
  color: #b8b8b8;
  background: white;
  border-color: var(--skyBlue)
}

.leaflet-control-zoom a:active {
  transform: scale(.93);
}

.half-width {
  width: 50%;
}

.hidden {
  display: none !important
}

.unread {
  position: absolute !important;
  top: 5px;
  right: 30px;
  background: #35b8d3;
  color: #ffffff;
  font-size: 10px;
  border-radius: 500px;
  padding: 3px 5px;
  z-index: 9;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}


/* Markers template */

.markerTemplate {
  display: table;
  text-align: center;
  border: 4px solid transparent;
  user-select: none;
  outline: none;
  /* transition: transform 1s ease; */
}

.markerTemplate:before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background: url(/assets/img/icons/marker-peak.svg);
  left: calc(50% - 7.5px);
  bottom: -10px;
  z-index: -1;
  background-size: cover !important;
  background-position: center -1px;
}

.markerTemplate p {
  margin: 0;
  position: absolute;
  width: 100%;
  bottom: 11px;
  font-size: 10px;
  font-weight: 700;
  color: #979797 !important;
}

.hasAvatar {
  width: var(--markersSize);
  height: var(--markersSize);
  border-radius: 100px;
  margin: 0 auto;
  background-size: 100% !important;
  background-position: center -10px !important;
  background-repeat: no-repeat !important;
  position: relative;
  box-shadow: 0px 0px 9px 0px rgb(0 0 0);
}

.notAvatar {
  width: var(--markersSize);
  height: var(--markersSize);
  border-radius: 100px;
  background: #ffd16f;
  margin: 0 auto;
  font-weight: 800;
  line-height: 33px;
  font-size: 11px;
  color: #bda46d;
  position: relative;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
}

.notDriver {
  width: var(--markersSize);
  height: var(--markersSize);
  border-radius: 100px;
  background: #a6a6a6;
  margin: 0 auto;
  font-weight: 800;
  line-height: 33px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.75);
}

.notDriverName {
  line-height: 9px;
  color: #ffffff !important;
  top: 17px;
  display: table;
  vertical-align: middle;
}

.notAvatar > div,
.hasAvatar > div {
  position: absolute;
  width: 68px;
  height: 30px;
  background: #ffffff;
  left: 1px;
  bottom: 1px;
  border-bottom-left-radius: 100px;
  border-bottom-right-radius: 100px;
}

.markerTemplate_selected {
  border: 4px solid var(--skyBlue);
  border-radius: 50px;
}

.markerTemplate_hide {
  display: none;
}

.config-helper {
  position: fixed;
  background: #ffffff;
  z-index: 9999;
  padding: 20px;
  margin: 0;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 20%);
  border-radius: 0 10px 10px;
}

.config-helper b {
  color: #b2914d;
}

.config-helper p {
  margin: 0;
}

.close-config-helper {
  margin-top: -13px;
  color: #cccccc;
  padding: 0 5px 3px 5px;
  line-height: 16px;
  border-radius: 10px;
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.close-config-helper:active {
  transform: scale(.95);
}


/* Map Cluster Styles */

.marker-cluster-small {
  width: 60px !important;
  height: 60px !important;
  border-radius: 60px;
  background-color: rgba(255, 253, 250, 0.7);
}

.small {
  font-size: 13px;
}

.marker-cluster-small:after {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  background: url(/assets/img/icons/cars-gray.svg);
  left: calc(50% - 12px);
  top: 10px;
  background-size: cover !important;
}

.marker-cluster div {
  width: 50px;
  height: 50px;
  border-radius: 60px;
  background-color: #fffdfa;
}

.marker-cluster span {
  line-height: 70px;
  color: var(--lightGrey);
  font-weight: 700;
}

.leaflet-tooltip-top:before {
  border-top-color: #606159;
}


/* Tooltip */

.leaflet-tooltip {
  padding: 2px 7px;
  background-color: inherit;
  border: inherit;
  color: inherit;
  opacity: 1 !important;
  box-shadow: none !important;
}

.tooltip {
  max-width: 300px;
  padding: 20px;
  position: relative;
  background: #ffffff;
  border-radius: 25px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
}

.arrow {
  position: absolute;
  bottom: -15px;
  left: calc(50% - 14px);
  width: 30px;
  height: 17px;
  overflow: hidden;
  text-align: center;
}

.arrow > div {
  width: 20px;
  height: 20px;
  margin: -9px auto 0 auto;
  background: #ffffff;
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.3);
}

.head {
  /* border-bottom: 1px solid #e3e3e3;
    padding: 0px 0 21px 0; */
  position: relative;
}

.head p,
.content p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0
}

.head p {
  color: #494949;
  font-size: 17px;
  line-height: 20px;
}

.tooltip .head p {
  line-height: 16px;
}

.tooltip .head p.last-update {
  font-size: 10px;
  color: gray;
}

.head .driver-name {
  color: var(--skyBlue);
}

.head p .vehicle {
  font-size: 12px;
  color: gray;
}

.head p .plate {
  font-size: 10px;
  color: gray;
}

.head p .imei {
  font-size: 10px;
  color: gray;
}

.head p .status {
  font-size: 10px;
  background-color: #aaa;
  text-transform: capitalize;
  font-weight: bold;
  display: inline-block;
  padding: 2px 6px;
  border-radius: 10px;
  margin: 2px 0 4px;
  color: white;
}

.head p .status.tripdriving {
  background-color: #35b8d3;
}

.head p .state {
  font-size: 10px;
  color: gray;
}

.head p .state i {
  width: 10px;
  height: 10px;
  background: #888;
  display: inline-block;
  border-radius: 50%;
  position: relative;
  margin-right: 4px;
  top: 2px;
}

.head p .state i.on {
  background: #0c0;
}

.head p .state i.off {
  background: #c00;
}

.tooltip .head .driver-name {
  padding-bottom: 4px;
}

.tooltip .head .last-update-label {
  font-weight: bold;
}

.tooltip-picture {
  position: absolute;
  left: 0;
  top: 0;
  width: 35px;
  height: 35px;
  border-radius: 500px;
  border: 1px solid #e3e3e3;
  background-size: cover !important;
  background-position: top center !important
}

.scoring {
  position: absolute;
  right: 21px;
  top: 0;
  text-align: right;
  font-size: 14px;
  line-height: 19px;
}

.scoring span {
  position: relative;
}

.scoring img {
  width: 20px;
  position: absolute;
  top: -3px;
  right: -25px;
}

.content {
  position: relative;
  padding-top: 15px;
  font-size: 12px;
}

.content p {
  padding-left: 35px;
  line-height: 28px
}

.content img {
  width: 25px;
  position: absolute;
  top: 15px;
  border-radius: 500px;
  border: 1px solid #e3e3e3;
}

.content .pipe {
  color: #e3e3e3
}

span.tooltip-plate {
  padding: 5px;
  font-size: 12px;
  border: 1px solid #e3e3e3;
  color: var(--skyBlue);
  border-radius: 9px;
}

mat-dialog-container {
  padding: 0 !important;
}

iframe {
  margin-bottom: -4px !important;
  border: none !important;
  width: 100%;
  height: 90vh;
}

.cdk-overlay-container {
  z-index: 999999;
}

.mat-primary .mat-pseudo-checkbox-checked {
  background: var(--skyBlue);
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--skyBlue);
}

.mat-select-panel {
  border-radius: 20px !important;
}

finder-lists .mat-list-text {
  width: 80% !important;
}

.template-list mat-list-item .mat-list-text {
  width: 100% !important;
}

finder-lists .geofence .mat-list-text {
  width: 66% !important;
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: initial;
}

span.mat-expansion-indicator {
  display: none !important;
}

.mat-expansion-panel-body {
  background: #ffffff !important;
  padding: 0px 20px 0px 30px !important;
  /* max-height: 75vh;
    overflow-y: auto;  */
  position: relative;
}

.mat-expanded {
  opacity: 1;
  margin: 0 !important;
}

.score-help-modal {
  height: 50vh
}

.entity-list {
  padding: 0 !important;
  width: var(--fullWidth)
}

.entity h3.subheader {
  margin: 20px 0px 10px 20px;
  font-size: 14px;
  color: #a1a1a1;
  font-weight: 600;
}

mat-spinner,
.mat-progress-spinner svg {
  width: 100% !important;
  height: 100% !important;
}

.mat-progress-spinner circle,
.mat-spinner circle {
  stroke: var(--skyBlue) !important;
}

.entity-list .mat-expanded mat-icon.picture {
  border: 3px solid var(--skyBlue) !important;
}

.mat-expanded h4 {
  font-size: 20px !important;
}

mat-expansion-panel {
  border-bottom: 1px solid #e3e3e3 !important;
}

vehicle-template:nth-child(1) mat-expansion-panel,
driver-template:nth-child(1) mat-expansion-panel,
device-template:nth-child(1) mat-expansion-panel,
admin-template:nth-child(1) mat-expansion-panel,
geofence-template:nth-child(1) mat-expansion-panel,
notification-template:nth-child(1) mat-expansion-panel {
  border-top: 1px solid #e3e3e3;
}

.mat-list .vehicle-tags .mat-line {
  white-space: pre-wrap !important;
}

.mat-list .vehicle-tags .mat-list-text,
.mat-list .vehicle-shifts .mat-list-text {
  width: 100% !important;
}

.template-list mat-list-item {
  cursor: default !important;
}

snack-bar-container {
  color: #ffffff !important;
  text-align: center !important;
}

.snack-primary {
  background: var(--skyBlue) !important;
}

.snack-success {
  background: var(--success) !important;
}

.snack-danger {
  background: var(--danger) !important;
}

.snack-warning {
  background: var(--warning) !important;
}

.danger {
  color: var(--danger)
}

ul.geocoder-control-suggestions.leaflet-bar {
  top: 30px;
  position: relative;
  background: white;
  border: none;
  border-radius: 10px;
}

.geocoder-control-expanded ul.geocoder-control-suggestions.leaflet-bar {
  padding: 10px;
}

.clear-search-input {
  position: absolute;
  right: 4px;
  top: 5px;
  background: #e3e3e3;
  border-radius: 100px;
  z-index: 1111;
  padding: 4px 10px;
  color: #ffffff;
  cursor: pointer;
}

ul.geocoder-control-suggestions.leaflet-bar li {
  list-style: none;
  border-bottom: 1px solid #e3e3e3;
  padding: 10px 0;
  color: gray;
  cursor: pointer;
}

ul.geocoder-control-suggestions.leaflet-bar li:hover {
  color: #545454;
}

/* Map controls and overlays */

.leaflet-top.leaflet-left {
  right: 20px !important;
  top: 210px;
  left: initial !important;
  z-index: 1001;
}

.map-logo {
  position: fixed;
  z-index: 460;
  width: 100px;
  right: 10px;
  top: 20px;
}

.map-logo.notification-not-enabled {
  top: 38px !important;
}

.map-logo img {
  width: 100%
}

.shiftclick {
  background: rgba(255, 255, 255, 0.7);
  padding: 5px 10px 5px 70px;
  border-radius: 50px;
  position: fixed;
  z-index: 401;
  bottom: 20px;
  color: #676666;
  cursor: pointer;
  right: initial;
  left: 20px;
}

.shiftclick div {
  width: 50px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  height: 20px;
  position: absolute;
  left: 10px;
  top: 8px;
  background: url('/assets/img/icons/shiftclick.svg')
}

.live {
  background: rgba(255, 255, 255, 0.7);
  padding: 5px 10px 5px 35px;
  border-radius: 50px;
  position: fixed;
  z-index: 9999;
  bottom: 20px;
  right: 20px;
  font-size: 13px;
  color: #676666;
  cursor: pointer;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.41);
}

.live div {
  width: 15px;
  height: 15px;
  position: absolute;
  left: 10px;
  border-radius: 50px;
  top: 6px;
  background: #fff;
  border: 1px solid var(--success);
}

.alive {
  transition: all 0.5s ease-in-out;
  animation: blink normal 1.5s infinite ease-in-out;
}

@keyframes blink {
  0% {
    background-color: var(--skyBlue)
  }

  50% {
    background-color: #9cc3fa
  }

  100% {
    background-color: var(--skyBlue)
  }
}

.header,
.header .mat-list-item-content {
  padding: 0 !important
}


/* Single trip map overlays */

.trip-map-overlays {
  display: flex;
  position: fixed;
  z-index: 405;
  bottom: 20px;
  right: 10px
}

.leaflet-control-attribution {
  display: none;
}

.trip-map-overlays .indicators {
  padding: 10px 20px;
  background: #fff;
  border-radius: 50px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
  color: #676666;
  cursor: pointer;
  border: 2px solid transparent;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.32);
  transition: border-color .5s ease;
}

.trip-map-overlays .indicators img {
  width: 20px;
  margin-bottom: -4px;
  margin-right: 10px;
}

.trip-map-overlays .indicators:nth-child(1) img {
  margin-right: 0px;
}

.trip-map-overlays .speed-ranges {
  background: #676666;
  border-radius: 50px;
  color: #fff;
  padding: 10px 20px;
  font-size: 14px;
  display: flex;
  font-weight: 600;
}

.ranges {
  width: 250px;
  height: 6px;
  background: #ff440f;
  margin: 10px 3px 0px 10px;
  border-radius: 50px;
  border: 1px solid #fff;
  position: relative;
}

.trip-map-overlays .ranges span {
  position: absolute;
  font-size: 9px;
  top: 8px;
}

.trip-map-overlays .ranges span:nth-child(1) {
  top: -15px;
  left: 0%;
}

.trip-map-overlays .ranges span:nth-child(2) {
  top: -15px;
  left: 57%;
}

.trip-map-overlays .ranges span:nth-child(3) {
  top: -15px;
  left: 83%;
}

.trip-map-overlays .ranges span:nth-child(4) {
  left: 0%;
}

.trip-map-overlays .ranges span:nth-child(5) {
  left: 57%;
}

.trip-map-overlays .ranges span:nth-child(6) {
  left: 87%;
}

.gradient-baja-alta {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 64%;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border-right: 1px solid #fff;
  background: rgb(105, 220, 235);
  /* Old browsers */
  background: -moz-linear-gradient(left, rgba(105, 220, 235, 1) 0%, rgba(243, 131, 100, 1) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(105, 220, 235, 1) 0%, rgba(243, 131, 100, 1) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(105, 220, 235, 1) 0%, rgba(243, 131, 100, 1) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#69dceb', endColorstr='#f38364', GradientType=1);
}

form.geofences section.example-section .mat-checkbox span.mat-checkbox-label {
  color: #818181;
}

.leaflet-draw-toolbar a:hover {
  color: var(--skyBlue) !important;
}

ul.leaflet-draw-actions.leaflet-draw-actions-bottom a {
  background: gray;
  border-radius: 100px !important;
}

ul.leaflet-draw-actions.leaflet-draw-actions-bottom,
ul.leaflet-draw-actions.leaflet-draw-actions-top {
  top: 35px !important;
}

form.geofences section.example-section span.mat-checkbox-label {
  display: flex;
}

form.geofences section.example-section .mat-checkbox.mat-checkbox-checked span.mat-checkbox-label {
  color: #5086d2;
}

form.geofences section.example-section span.mat-checkbox-label img {
  margin-left: 5px;
}

form.geofences section.example-section .mat-checkbox span.mat-checkbox-label img {
  filter: grayscale(1);
}

.leaflet-retina .leaflet-draw-toolbar a {
  background-image: inherit !important;
}

form.geofences section.example-section .mat-checkbox.mat-checkbox-checked span.mat-checkbox-label img {
  filter: grayscale(0);
}

form.geofences button.btn-color {
  border-radius: 12px;
  padding: 4px;
  height: unset;
  font-size: 0.7rem;
  border: 1px solid var(--skyBlue);
  background: #fff;
  color: var(--skyBlue);
  margin-bottom: 5px;
  min-width: 40px;
  outline: none;
}

form.geofences button.btn-color.selected {
  color: #fff;
  background: var(--skyBlue);
}

form.geofences .setTime {
  padding: 5px 10px;
  margin-left: 10px;
  box-shadow: 0 1px 8px 0 rgb(0 0 0 / 20%);
  border: none;
  border-radius: 30px;
  color: var(--skyBlue);
  outline: none !important;
}

form.geofences mat-form-field.Mask1 {
  margin: 0;
  padding: 0 0 0 1rem;
  height: 42px;
  width: 89%;
  margin-left: 7px;
}

form.geofences input.search {
  border: none;
  outline: none;
}

.trip-range-tooltips {
  background: #494949;
  border-radius: 20px;
  color: #e7e7e7;
  margin-bottom: -3px;
}

.icon-dashcam {
  background-color: #63A4FF;
  padding: 8px 6px 8px 6px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.trip-range-tooltips span {
  display: block;
}

.ev-button-inactive {
  background: #7171716e !important;
  color: #f1f1f1 !important;
  cursor: default !important;
  box-shadow: none !important;
}

.ev-button-selected {
  border: 2px solid;
  border-color: var(--skyBlue) !important;
}

.no-margin {
  margin: 0
}

.gold {
  color: #d2b46c
}

.no-padding {
  padding: 0 !important
}

.text-center {
  text-align: center
}

.outlined-button {
  text-decoration: none;
  border: 1px solid var(--skyBlue) !important;
  color: var(--skyBlue) !important;
  border-radius: 40px !important;
  transition: background .3s ease;
  background: transparent;
  cursor: pointer;
  padding: 5px 10px;
  text-align: center;
  display: block;
}

.hide-show-text .button-name {
  width: 0px;
  overflow: hidden;
  transition: width .5s ease, opacity .5s ease;
  opacity: 0;
  white-space: nowrap;
}

.hide-show-text:hover .button-name {
  width: 100%;
  opacity: 1;
}

driver-template .outlined-button > span, dashcam-template .outlined-button > span {
  display: flex;
}

.outlined-button span img {
  width: 100%;
}

span.icon-wrapper {
  width: 30px;
  height: 20px;
  display: inline-flex;
}

.list-section > div .outlined-button {
  margin-right: 10px;
}

.fixed-bottom {
  position: absolute !important;
  bottom: 65px;
  left: calc(50% - 50px);
  background: #fff;
}

.button-disabled {
  border-color: #80808066 !important;
  color: #80808066 !important;
}

.profile-modal .mat-dialog-container {
  background: transparent !important;
  border-radius: 10px !important;
}

.confirm {
  padding: 20px
}

.link {
  cursor: pointer;
  text-align: center;
  display: block;
  color: var(--skyBlue)
}

.trips .mat-content {
  display: initial !important;
}

.stopped,
.unfocused {
  opacity: .6
}

.moving p {
  color: var(--skyBlue) !important;
}

.mat-dialog-container {
  position: relative;
  border-radius: 20px !important;
}

.closeModalButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: var(--borderGray);
  color: #ffffff;
  border-radius: 500px;
  border: 2px solid #ffffff;
  font-size: 12px;
  padding: 2px 7px 3px 7px;
  cursor: pointer;
  outline: none;
}

.sort-options {
  color: #818181;
  text-transform: capitalize;
}

app-kpis img.logo {
  right: 50px !important;
}

.suggestions .mat-list-text {
  padding-left: 10px !important
}

.suggestions .mat-list .mat-list-item {
  height: fit-content !important;
  padding: 0px 0 20px 0;
}

.suggestions .mat-list .mat-list-item .mat-line {
  white-space: initial;
  overflow: initial;
}

.suggestions .mat-list-item-content {
  padding: 0 !important
}

mat-list-item.list-hover {
  transition: background .5s ease;
  cursor: pointer;
}

mat-list-item.list-hover:hover {
  background: rgb(245, 245, 245);
}

app-map .marker-states {
  position: absolute;
  bottom: 47px;
  right: 20px;
  z-index: 99999;
  background: #fff;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: gray;
  font-size: 13px;
  width: 200px;
  box-shadow: 0px -2px 6px 2px rgba(0, 0, 0, 0.2);
}

.apexcharts-menu-icon {
  display: none;
}

.asset-container {
  min-width: 150px;
  position: relative;
}

span.badge-nosignal {
  border-radius: 30px;
  background: #a9a9a9;
  padding: 5px 10px;
  display: inline-flex;
  color: #ffffff;
  font-size: 12px;
  margin-top: 5px;
}

span.badge-nosignal img {
  margin-right: 5px;
}

.marker-type-asset .hasAvatar > div {
  background: #ffffff;
}

.asset-icon {
  background-color: #ffffff;
  background-size: 40% !important;
  background-position: center 4px !important;
  background-repeat: no-repeat !important;
}

.asset-icon-wireless {
  background-image: url(/assets/img/icons/assetusb.svg);
}

.asset-icon-wired {
  background-image: url(/assets/img/icons/asset12v.svg);
}


/* battery level */

.batterylevel {
  background-size: cover !important;
  background-position: center center !important;
  width: 30px;
  height: 15px;
  position: relative;
}

.batterylevel-bar-container {
  height: 59%;
  width: 67%;
  position: absolute;
  top: 3px;
  left: 3px;
}

.batterylevel-bar-container > div {
  height: 100%;
  border-radius: 2px;
}

.batterylevel-container {
  display: flex;
  justify-content: flex-end;
}

.batterylevel-container span {
  margin-left: 10px;
}

.asset-head div:nth-child(1) {
  color: #676666;
  font-weight: 800;
}

.asset-body {
  margin-top: 10px;
  font-size: 14px;
  color: #818181
}

.batterylevel-full {
  background-image: url(/assets/img/icons/battery-full.svg);
}

.batterylevel-full .batterylevel-bar-container div {
  background: #73cc4a;
}

.batterylevel-color-full {
  color: #73cc4a
}

.batterylevel-medium {
  background-image: url(/assets/img/icons/battery-mid.svg);
}

.batterylevel-medium .batterylevel-bar-container div {
  background: #ffc03a;
}

.batterylevel-color-medium {
  color: #ffc03a
}

.batterylevel-low {
  background-image: url(/assets/img/icons/battery-low.svg);
}

.batterylevel-low .batterylevel-bar-container div {
  background: #ef6b47;
}

.batterylevel-color-low {
  color: #ef6b47
}

.marker-sample-active {
  color: #38B6D1 !important
}

.marker-sample-offline {
  filter: grayscale(1);
  opacity: .7
}

.marker-states .state {
  position: relative;
  display: flex;
}

.marker-states .state img {
  width: 50px
}

.state .plate {
  position: absolute;
  left: 15px;
  bottom: 15px;
  font-size: 7px;
  font-weight: 600;
  color: rgb(139, 139, 139);
}

.state-name {
  line-height: 50px;
  padding: 0 10px
}

.marker-states-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999999;
}

.backdrop {
  z-index: 500;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--backDropColor);
  width: 100vw;
  height: 100vh;
  display: flex;
}

/* asset marker */

.asset-container .asset-head {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  align-items: center;
  grid-template-rows: 20px;
}

.states-opened {
  border-radius: 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  min-width: calc(200px - 25px);
}

.notifications-shortcut {
  z-index: 402px;
  background: #ffffff;
  text-align: center;
  height: 100%;
  border-radius: 90px;
  font-size: 17px;
  margin-left: 10px;
  cursor: pointer;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
}

.notifications-shortcut mat-icon {
  margin-top: 11px;
}

.notifications-shortcut:active {
  transform: scale(.8)
}

app-feedback .mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #62a4ff;
}

.feedback-panel mat-dialog-container {
  padding: 20px !important;
  text-align: center;
  background: #ffffff;
}

.panel-error {
  background: var(--danger);
}

.panel-success {
  background: var(--success);
}

.panel-fleetr-msg {
  background: var(--fleetr);
  font-weight: 700;
}


/* app-finder-lists expansion panel */


/* app-finder-lists */

app-finder-lists mat-expansion-panel.mat-expanded {
  border: 2px solid #62a4ff !important;
  padding-top: 20px !important;
  margin-bottom: 20px;
}

mat-expansion-panel.driver-collapse {
  padding-bottom: 0 !important;
}

app-finder-lists mat-expansion-panel-header {
  padding: 0 !important;
}

app-finder-lists .mat-expanded mat-expansion-panel-header mat-icon.picture {
  width: 60px !important;
  height: 60px !important;
}

app-finder-lists .mat-expanded mat-expansion-panel-header {
  height: initial !important;
  position: relative;
  margin-top: 20px !important;
}

app-finder-lists .mat-expanded mat-expansion-panel-header .mat-list .mat-list-item.mat-2-line {
  height: initial !important;
}

app-finder-lists .mat-expanded mat-expansion-panel-header .mat-list .mat-list-item .mat-list-item-content {
  display: block !important;
}

app-finder-lists .mat-expanded mat-expansion-panel-header .mat-list-text,
app-finder-lists .mat-expanded .mat-expansion-panel-body {
  padding: 0 !important;
}

app-finder-lists mat-expansion-panel-header .phone {
  display: none
}

app-finder-lists .mat-expanded mat-expansion-panel-header .phone {
  position: absolute;
  right: 30px;
  top: 20px;
  display: block;
  color: #989898;
  pointer-events: auto;
}

app-finder-lists .mat-expanded mat-expansion-panel-header .phone img {
  margin-bottom: -5px;
}

app-finder-lists .mat-expanded mat-expansion-panel-header .current-car,
app-finder-lists .mat-expanded mat-expansion-panel-header .driver-picture {
  display: none;
}

app-finder-lists .mat-expanded .brand {
  font-size: 36px !important;
  line-height: 56px !important;
}

app-finder-lists vehicle-template .mat-expanded .current-driver {
  text-align: left !important;
  font-size: 14px !important;
}

app-finder-lists vehicle-template p.trips_services button {
  width: 100% !important;
}

app-performance app-filters .mat-form-field-type-mat-native-select .mat-form-field-infix::after,
app-performance app-filters input,
app-performance app-filters label,
app-performance app-filters select,
app-performance app-filters button.mat-icon-button {
  color: #fff !important;
}

app-performance app-filters select {
  background: transparent;
  border: none;
  border-bottom: 1px solid #fff;
  width: 100%;
}

app-performance app-filters select option {
  background: var(--skyBlue);
}

notifications .mat-list .mat-list-item.mat-2-line {
  height: 80px;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: var(--skyBlue);
}

.mat-datepicker-content-touch {
  margin: 0 !important;
}

.date-picker .mat-form-field-underline {
  display: none;
}

.date-picker .mat-form-field-prefix,
.date-picker .mat-form-field-suffix {
  top: -6px;
  right: 6px;
  color: var(--skyBlue) !important;
}

.date-picker .mat-form-field-appearance-legacy .mat-form-field-wrapper {
  padding-bottom: 0;
}

.date-picker .mat-form-field {
  display: inline-table !important;
}

.disconnected {
  opacity: .5
}

.date-picker .mat-form-field-infix {
  border: none;
}

.TripDriving div p {
  color: #38B6D1 !important;
}

.Unknown {
  filter: saturate(0.2) brightness(1.1) contrast(0.9);
}

.Disconnected {
  opacity: 0.5 !important;
}

.Disconnected .notAvatar,
.Unknown .notAvatar {
  background-color: rgb(139, 139, 139) !important;
  color: #d6d6d6 !important
}

.list-section {
  display: grid;
  grid-template-columns: 100%;
  padding: 0 20px;
  margin: 10px 0;
}

.list-section > div {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.mat-list .mat-list-item .mat-list-item-content,
.mat-list .mat-list-option .mat-list-item-content,
.mat-nav-list .mat-list-item .mat-list-item-content,
.mat-nav-list .mat-list-option .mat-list-item-content,
.mat-selection-list .mat-list-item .mat-list-item-content,
.mat-selection-list .mat-list-option .mat-list-item-content {
  padding: 0 20px !important;
}


/* Driver fixes */

.mat-expanded .entity-list .noPicture {
  font-size: 24px !important;
  line-height: 53px !important;
}

.mat-expanded .entity-list .driver-title {
  margin: 10px 0
}

.mat-expanded .entity-list .name {
  margin-top: 20px !important;
}


/* app-vehicle-health */


/* add maintenance modal */

add-maintenance .container h3 {
  color: var(--skyBlue);
}

add-maintenance .container h3 span {
  color: #bebebe;
  font-size: 12px;
}

add-maintenance h3 {
  color: var(--skyBlue);
  background: white;
  margin: 0;
  position: absolute;
  padding: 10px 20px;
  box-shadow: 0px 4px 8px -6px rgba(0, 0, 0, 0.65);
  top: 0;
  left: 0;
  width: calc(100% - 40px);
  z-index: 10;
}

add-maintenance .vehicle-list {
  padding: 65px 20px 10px 20px;
}

add-maintenance .vehicle-list .vehicle-grid {
  border-bottom: 1px solid #e3e3e3;
  padding: 15px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  display: grid;
  align-items: center;
  margin: 20px 0 0 0;
}

add-maintenance .vehicle-list .vehicle-grid .vehicle {
  margin: 0;
}

add-maintenance .clear-selection {
  background: #c8c8c8 !important;
  padding: 0px 7px 1px 7px !important;
  display: initial !important;
}

add-maintenance .date-picker {
  cursor: pointer;
  text-align: center;
  padding: 20px 0;
  margin-top: 20px;
  opacity: 0;
  transition: opacity .3s ease, margin-top .3s ease;
}

add-maintenance .date-picker-open {
  margin-top: 0;
  opacity: 1;
}

add-maintenance .date-picker p > span {
  line-height: 35px;
  margin: 0 10px 0 0px;
  font-size: 16px;
  color: #676666;
}

add-maintenance .date-picker p {
  display: inline-flex;
  margin: 0;
}

add-maintenance .date-input {
  border-radius: 20px;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
  font-size: 14px;
}

add-maintenance .date-input::placeholder {
  font-size: 14px !important;
}

add-maintenance mat-datepicker-toggle button.mat-icon-button {
  top: -5px;
  right: 6px;
}

add-maintenance .mat-form-field-underline {
  display: none;
}

add-maintenance .maintenance-form .text-input input {
  border-bottom: 1px solid #e3e3e3;
  padding: 10px 0 5px 0;
  font-size: 12px;
  margin-bottom: 10px;
}

add-maintenance .mat-form-field-wrapper {
  padding: 0 !important;
}

add-maintenance .add-maintenance-action {
  padding: 20px 0;
  text-align: center;
}

iframe#launcher {
  left: -20px !important;
  bottom: 10px !important;
  transform: scale(0.6);
  visibility: hidden;
}

#chargebee-portal {
  position: absolute;
  top: 20px;
  left: 30px;
  z-index: 99999;
}

app-welcoming p {
  font-size: 16px;
  line-height: 1.38;
  letter-spacing: normal;
  color: #8d8d8d !important;
}

app-welcoming .fleetr-video {
  width: 100%;
  border-radius: 20px;
  outline: none;
}

app-welcoming .feature-row {
  display: grid;
  grid-template-columns: 70px calc(100% - 70px);
  align-items: center;
}

app-welcoming .feature-row img {
  width: 70%;
}

app-welcoming .feature-text {
  font-size: 20px;
  color: var(--mango);
  font-weight: 600;
}

mat-radio-group .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--skyBlue);
}

mat-radio-group .mat-radio-button.mat-accent .mat-radio-inner-circle,
.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple),
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple,
.mat-radio-button.mat-accent:active .mat-radio-persistent-ripple {
  background-color: var(--skyBlue);
}

mat-radio-group .mat-radio-label-content {
  color: #676666 !important;
}

/*
.pickers .mat-form-field-infix {
    display: none !important;
}

.pickers .mat-form-field-wrapper {
    visibility: hidden;
}

*/

/* map tool buttons */

.map-tool-buttons {
  width: 40px;
  position: fixed;
  z-index: 460;
  top: 100px;
  right: 30px;
}

.map-tool-buttons div {
  width: 100%;
  background: #ffffff;
  margin: 5px 0;
  cursor: pointer
}

.map-type {
  background-size: 100% !important;
  border: 2px solid var(--skyBlue);
}

.map-tool-icons {
  background-size: 20px !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.zoom div {
  height: 40px;
  border-radius: 100px;
}

.zoom div:active,
.map-type:active,
.watch-all:active {
  transform: scale(.9);
}

.circle-container {
  height: 40px;
  border-radius: 100px;
}

.oval-container {
  height: 90px;
  border-radius: 100px;
  border: 2px solid transparent;
}

#map-wrapper {
  width: 100%;
  height: 500px;
  position: relative;
  border: 1px solid black;
}

#map {
  width: 100%;
  height: 100%;
  background-color: green;
}

#button-wrapper {
  position: absolute;
  bottom: 10px;
  width: 100%;
  border: 1px solid red;
}

.Mask1 .mat-form-field-infix {
  padding: 0px;
  border-top: 0px;
}

.Mask1 .mat-form-field-wrapper {
  padding-bottom: 0px;
}

.Mask1 .mat-form-field-infix input {
  height: 35px;
  width: 80%;
  position: relative;
  top: -8px;
  padding-left: 10px;
}

.Mask1 .mat-form-field-infix img {
  margin-top: 10px;
}

.w24 {
  width: 24px;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-ripple-element {
  background-color: #5086d2;
}

.d-flex {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
}

.customSearch {
  border: 1px solid #d6d6d6;
  border-radius: 100px;
  width: calc(100% - 50px);
  padding-right: 40px;
  height: 35px;
  margin-top: 10px;
  padding-left: 10px;
}

.customSearch:focus {
  outline: none;
}

.searchCross {
  position: absolute;
  right: 7px;
  top: 18px;
}

.mat-list .mat-list-item .mat-list-item-content {
  padding: 0 20px 0 6px !important;
}

.hr {
  margin-top: 50px;
  border: 1px solid #ebebeb;
}

.listTyle {
  width: 96%;
}

.setCross {
  width: 45px;
  display: inline-block;
}

.entity {
  margin-bottom: 10px;
}

.gray {
  color: gray
}

.bottomStrip {
  height: 45px;
  background: #000;
  text-align: center;
  position: absolute;
  bottom: 0;
  z-index: 401;
  width: calc(100% - 420px);
}

.entity-list-addedbox {
  overflow-y: auto;
  min-height: 250px;
  margin: 5px;
}

.Schedules label {
  margin-bottom: 20px !important;
}

.ng-star-inserted hr {
  margin-top: 10px !important;
}

.btnStrip {
  color: #fff;
  background: #000;
  border: 0px;
  margin-top: 13px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-2 {
  margin-right: 16px;
}

.hideStrip {
  display: none;
}

/* Geofence search bar */
.leaflet-top.leaflet-left .geocoder-control.leaflet-control {
  position: fixed;
  top: 20px;
  width: 300px;
  height: 40px;
  left: calc(var(--sideWindowBarWidth) + var(--sideToolBarWidth) + 20px);
}

.leaflet-top.leaflet-left .geocoder-control.leaflet-control input {
  width: calc(100% - 40px);
  border-radius: 100px;
  border: none !important;
  padding: 5px 5px 5px 35px;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 20%);
  outline: none !important;
  color: gray;
}

.leaflet-draw.leaflet-control .leaflet-draw-toolbar {
  display: flex;
  border: 0px;
}

.leaflet-top.leaflet-right {
  right: 170px;
  top: 21px;
}

.leaflet-draw.leaflet-control .leaflet-draw-toolbar a {
  margin-right: 10px;
}

img[alt=Google] {
  display: none;
}

.actionpopuup {
  text-align: center;
  padding: 20px;
}

.actionpopuup .actions button:nth-child(2) {
  margin-left: 10px;
  border-color: transparent !important;
  color: gray !important;
}

#scroll-window side-info-window > .container.removespace {
  position: relative;
  padding-top: 70px !important;
  z-index: 9;
  height: calc(100vh - 80px);
}

#scroll-window side-info-window > .container {
  height: calc(100vh - 180px);
  width: var(--sideWindowBarWidth);
}

.leaflet-draw-draw-polygon {
  position: relative;
}

.geo-selected mat-list-item > div {
  border: 1px solid var(--skyBlue);
}

.leaflet-draw-draw-polygon::after {
  position: absolute;
  content: 'Polygon';
  right: 15px;
}

.leaflet-draw-draw-circle {
  position: relative;
}

.leaflet-draw-draw-circle::after {
  position: absolute;
  content: 'Radius';
  right: 15px;
}

.leaflet-draw-toolbar a {
  width: 100px !important;
  background-image: none;
  border: 1px solid var(--skyBlue) !important;
  border-radius: 22px !important;
  height: 30px !important;
  color: var(--skyBlue);
  font-size: 14px;
}

.leaflet-draw-draw-circle::before {
  position: absolute;
  content: '';
  left: 12px;
  background-image: url('/assets/img/icons/radius.svg');
  width: 22px;
  height: 22px;
  background-repeat: no-repeat;
  top: 4px;
}

.leaflet-draw-draw-polygon::before {
  position: absolute;
  content: '';
  left: 10px;
  background-image: url('/assets/img/icons/poligon.svg');
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  top: 4px;
}

.geocoder-control.leaflet-control {
  position: relative;
}

.geocoder-control.leaflet-control input:focus {
  z-index: 10;
}

.geocoder-control.leaflet-control:before {
  content: '';
  position: absolute;
  left: 11px;
  background-image: url(/assets/img/icons/buscar.svg);
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  top: 10px;
  z-index: 99;
}

button.mat-icon-button {
  list-style: none;
}

.mat-expanded .mat-line {
  margin: 0 15px !important;
}

vehicle-template .mat-expanded mat-icon.picture {
  margin: 0 15px !important;
}

.score-info-modal .container {
  padding: 20px !important;
}

.score-info-modal .container img {
  width: 100px !important;
}

.selected-driver-freemium {
  border: 1px solid var(--skyBlue);
}

.week-filter-scores .mat-form-field-wrapper {
  visibility: hidden;
  height: 0 !important;
  padding: 0 !important;
  overflow: hidden;
}

.app-down {
  padding: 30px;
  color: gray
}

.cm-body {
  color: var(--titleGray);
  line-height: 1.4rem;
  overflow-y: scroll;
  max-height: 65vh;
}

.cm-body * {
  margin: 0 0 .2rem;
}

.cm-body h1 {
  font-size: 20px;
}

.cm-body h2 {
  font-size: 16px;
}

.cm-body p {
  font-size: 14px;
}

.cm-body em {
  font-weight: 300;
}

.cm-body ol,
.cm-body ul {
  font-size: 14px;
  margin: 0 0 14px;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--lightBlue);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--white);
}

.styled-autocomplete .autocomplete-container {
  background: none;
  box-shadow: none;
  height: 31px;
}

.styled-autocomplete .autocomplete-container .suggestions-container {
  border: 1px solid var(--borderGray);
  border-top: none;
  border-radius: 0 0 15px 15px;
  padding-top: 24px;
  padding-bottom: 4px;
  margin-top: -20px;
  overflow: hidden;
  z-index: -1;
}

.styled-autocomplete .autocomplete-container .input-container input {
  border-radius: 30px;
  border: 1px solid var(--borderGray);
  height: 31px;
  font-family: 'Source Sans Pro', sans-serif;
}

.styled-autocomplete .autocomplete-container .suggestions-container ul li:hover {
  background-color: rgba(99, 164, 255, .2);
}

.styled-autocomplete .autocomplete-container .input-container .x {
  border: 1px solid var(--titleGray);
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

.styled-autocomplete .autocomplete-container .input-container .x i {
  font-size: 12px;
  position: absolute;
  font-weight: 600;
  color: var(--titleGray);
  margin-top: 2px;
  margin-left: 1.8px;
}

.mat-expansion-panel-content {
  font-family: 'Source Sans Pro', sans-serif !important;
}

/* Autocomplete style */

.fleetr-autocomplete {
  height: 36px;
}

.fleetr-autocomplete > * {
  height: inherit;
}

.fleetr-autocomplete .autocomplete-container {
  background: none;
  box-shadow: none;
  height: inherit;
}

.fleetr-autocomplete .autocomplete-container .input-container {
  height: inherit;
}

.fleetr-autocomplete .autocomplete-container .input-container input {
  border-radius: 30px;
  height: inherit;
  font-family: 'Source Sans Pro', sans-serif;
  border: 1px solid var(--borderGray);
}

.fleetr-autocomplete .autocomplete-container .suggestions-container {
  min-height: 56px;
  border: 1px solid var(--skyBlue);
  border-top: none;
  border-radius: 0 0 15px 15px;
  padding-top: 24px;
  padding-bottom: 4px;
  margin-top: -20px;
  overflow: hidden;
  z-index: -1;
}

.fleetr-autocomplete .autocomplete-container .not-found {
  background: none;
  border: none;
}

.fleetr-autocomplete .autocomplete-container .suggestions-container ul li:hover {
  background-color: rgba(99, 164, 255, .2);
}

.fleetr-autocomplete .autocomplete-container .suggestions-container ul li a {
  font-size: 14px;
  padding: 8px 15px;
}

.fleetr-autocomplete .autocomplete-container .input-container input:focus {
  border: 1px solid var(--skyBlue);
}

.fleetr-autocomplete .autocomplete-container .input-container .x {
  border: 1px solid var(--borderGray);
  color: var(--borderGray);
  border-radius: 50%;
  width: 16px;
  height: 16px;
}

.fleetr-autocomplete .autocomplete-container .input-container .x i {
  font-size: 16px;
  position: absolute;
  font-weight: 400;
  color: inherit;
  margin-top: 0px;
  margin-left: 0.2px;
}

.fleetr-autocomplete .autocomplete-container .input-container input:focus ~ .x {
  border: 1px solid var(--skyBlue);
  color: var(--skyBlue);
}

input[type="text"]:disabled {
  background-color: var(--lightGray);
}

.timepicker-backdrop-overlay {
  z-index: 999999 !important;
}

.timepicker-overlay {
  z-index: 999999!important;
}

.timepicker-button {
  border: 1px solid var(--skyBlue) !important;
  border-radius: 40px!important;
}

.timepicker{
  border-radius: 7px!important;
  width: 315px!important;
}

.timepicker__header{
  border-top-left-radius: 7px!important;
  border-top-right-radius: 7px!important;
}

.timepicker__actions{
  border-bottom-left-radius: 7px!important;
  border-bottom-right-radius: 7px!important;
}

.timepicker-button {
  margin-left: 5px;
}
